<template>
    <div class="upload-optimiser-widget">
        <file-pond name="test" ref="pond" label-idle="Drop files here..." v-bind:files="files" v-on:init="filePondInit"></file-pond>
    </div>
</template>

<script>
import vueFilePond, { setOptions } from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import { getToken, getActiveAccount } from '@/lib/Helpers';
import XLSX from 'xlsx';

//https://analytics.savernakecapital.com:8091/Request
//http://localhost:8090
setOptions({
    server: {
        url: 'http://localhost:8090/Request', 
        process: {
            url: `/?account=${getActiveAccount()}`,
            method: 'POST',
            withCredentials: false,
            headers: {
                Authorization: getToken()
            },
            timeout: 150 * 1000,
            onload: null,
            onerror: null,
            ondata: null
        },
        fetch: null,
        revert: null
    }
});

const FilePond = vueFilePond();

export default {
    data() {
        return {
            files: [],
            dismissCountDown: 0,
            variant: "warning",
            message: "",
            showAlert:false
        }
    },
    mounted() {
        this.$ws.on('parseexcel', this.fileUploadResponse);
    },
    beforeDestroy() {
        this.$ws.off('parseexcel', this.fileUploadResponse);
    },
    methods : {
        filePondInit() {},
        fileUpload(){
            //get excel file
            let element = document.getElementById("file")
            let file = element.files[0];
            let reader = new FileReader();
            let that = this;
            reader.onload = function(e) {
                //convert file data
                let data = new Uint8Array(e.target.result);
                //turn data to workbook
                let workbook = XLSX.read(data, {type: 'array'});

                let worksheets = {};
                //get json data from each worksheet
                for(let worksheetName of Object.keys(workbook.Sheets)){
                    if (["portfolio1w", "portfolio1d","portfolio1h"].includes(worksheetName.toLowerCase())){
                        worksheets[worksheetName] = (XLSX.utils.sheet_to_json(workbook.Sheets[worksheetName], {header:1}))
                    }
                }

                if(Object.keys(worksheets).length > 0){
                    //send data
                    that.fileSend(worksheets, file.name)
                }
                element.value = null;
                
            };
            reader.readAsArrayBuffer(file);
        },
        fileSend(data, fileName){
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'ParseExcel',
                args: {
                    Data: JSON.stringify(data),
                    FileName: fileName
                }
            });
        },
        fileUploadResponse(event){
            let response = event.response
            let countdown = 5;
            if(response.hasOwnProperty("error")){
                //show error message
                this.variant = "danger"
                this.message = response.error
                this.dismissCountDown = countdown;
            }else if(response.hasOwnProperty("success")){
                //show success message
                this.variant = "success"
                this.message = response.success
                this.dismissCountDown = countdown;
            }
        },
        countDownChanged(newNum){
            this.dismissCountDown = newNum;
        }
    },
    components: {
        FilePond
    }
}
</script>