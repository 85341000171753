<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Maintenance">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Dashboard settings</h4>
                        </div>
                        <div class="card-body">
                            <maintenance></maintenance>
                            <reload-strategies></reload-strategies>
                            <reload-index-returns></reload-index-returns>
                            <reload-tolerance-costs></reload-tolerance-costs>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Accounts</h4>
                        </div>
                        <div class="card-body">
                            <restart-accounts></restart-accounts>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Upload Optimiser Output</h4>
                        </div>
                        <div class="card-body">
                            <upload-optimiser></upload-optimiser>
                        </div>
                    </div>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import Maintenance from '@/widgets/Maintenance';
import RestartAccounts from '@/widgets/RestartAccounts';
import ReloadStrategies from '@/widgets/ReloadStrategies';
import ReloadIndexReturns from '@/widgets/ReloadIndexReturns';
import ReloadToleranceCosts from '@/widgets/ReloadToleranceCosts';
import UploadOptimiser from '@/widgets/UploadOptimiser';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        Maintenance,
        RestartAccounts,
        ReloadStrategies,
        ReloadIndexReturns,
        ReloadToleranceCosts,
        UploadOptimiser
    }
}
</script>