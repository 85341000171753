<template>
    <div class="reload-strategies-widget mt-2">
        <div class="row">
            <div class="col-6 mb-2">
                <p>Reload strategies from the database</p>
                <small class="form-text text-muted">This will reload all strategy definitions from the database for all accounts.</small>
            </div>
            <div class="col-6 text-right">
                <b-button variant="primary" :disabled="isLoading" type="button" @click="onReloadStrategiesSubmit">
                    <template v-if="isLoading">
                        <b-spinner small></b-spinner>
                    </template>
                    Reload
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'reload-strategies',
    data() {
        return {
            isLoading: false,
            hasSetupListeners: false
        };
    },
    beforeDestroy() {
        this.$ws.off('reloadstrategies', this.onReloadStrategies);
    },
    methods: {
        onReloadStrategiesSubmit() {
            this.isLoading = true;
            this.$ws.once('reloadstrategies', this.onReloadStrategies);
            this.$ws.send({
                accountName: this.$store.state.activeAccount,
                request: 'ReloadStrategies',
                args: {}
            });
        },
        onReloadStrategies(event) {
            this.$notify({
                group: 'primary',
                text: `Successfully reloaded ${this.$options.filters.number(event.response.strategies, 0)} strategies.`
            });

            this.isLoading = false;
        }
    }
}
</script>