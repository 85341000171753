<template>
    <div class="reload-tolerance-costs-widget mt-2">
        <div class="row">
            <div class="col-6 mb-2">
                <p>Reload tolerance costs</p>
                <small class="form-text text-muted">This will reload the tolerance costs CSV file, located on the Reporting server.</small>
                <small class="form-text text-muted">Accounts will need to be reloaded after if the reload can impact historical data.</small>
            </div>
            <div class="col-6 text-right">
                <b-button variant="primary" :disabled="isLoading" type="button" @click="onReloadToleranceCostsSubmit">
                    <template v-if="isLoading">
                        <b-spinner small></b-spinner>
                    </template>
                    Reload
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'reload-tolerance-costs',
    data() {
        return {
            isLoading: false,
            hasSetupListeners: false
        };
    },
    beforeDestroy() {
        this.$ws.off('reloadtolerancecosts', this.onReloadToleranceCosts);
    },
    methods: {
        onReloadToleranceCostsSubmit() {
            this.isLoading = true;
            this.$ws.once('reloadtolerancecosts', this.onReloadToleranceCosts);
            this.$ws.send({
                accountName: this.$store.state.activeAccount,
                request: 'ReloadToleranceCosts',
                args: {}
            });
        },
        onReloadToleranceCosts(event) {
            this.$notify({
                group: 'primary',
                text: `Successfully reloaded the ToleranceCosts.`
            });

            this.isLoading = false;
        }
    }
}
</script>