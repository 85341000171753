<template>
    <div class="restart-accounts-widget">
        <div class="row mb-2" v-for="account in accounts" :key="account.name">
            <div class="col-6 text-left">
                {{account.display}}
            </div>
            <div class="col-6 text-right">
                <b-button variant="primary" @click.prevent="sendAccountRestart(account)" :disabled="account.restarting" :title="`Restart ${account.display}`">Restart</b-button>
                <b-button variant="secondary" :style="(account.maintenance?'background-color:rgb(194, 29, 32)':'background-color:#32CD32')+';border:0px'" @click.prevent="sendAccountMaintenance($event, account)" :title="`Disable ${account.display}`">{{account.maintenance ? "Disabled": "Enabled"}}</b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <small>{{accounts.length==0?"Accounts loading or unable to load":"Accounts will remain accessible whilst restarting."}}</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'restart-accounts-widget',
    data() {
        return {
            hasSetupListeners: false,
            accounts: [],
            awaitingResponse: false
        };
    },
    mounted() {
        this.requests();
    },
    destroyed() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('restartaccountstatus', this.onRestartAccount);
        this.$ws.off('restartaccount', this.onRestartAccount);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('restartaccountstatus', this.onRestartAccount);
                this.$ws.on('restartaccount', this.onRestartAccount);
                //this.$ws.on('toggleAccountMaintenance', this.)
                this.hasSetupListeners = true;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'RestartAccountStatus',
                args: {}
            });
        },
        sendAccountRestart(account) {
            this.awaitingResponse = true;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'RestartAccount',
                args: {
                    accountName: account.name
                }
            });
        },
        sendAccountMaintenance(event, account){
            //console.log(this.accounts)
            this.awaitingResponse = true;
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'toggleAccountMaintenance',
                args: {
                    accountName: account.name,
                    maintenance: event.target.innerHTML == "Enabled"?false:true
                }
            });

            //toggle style and text
            event.target.innerHTML = event.target.innerHTML == "Enabled" ? "Disabled":"Enabled"
            event.target.style.backgroundColor = event.target.innerHTML == "Enabled" ? "#32CD32":"rgb(194, 29, 32)"
        },
        onRestartAccount(event) {
            let accounts = [];
            for(let account in event.response) {
                let matchedAccount = this.$store.getters.getAccount(account);

                let displayName = matchedAccount === null ? account : matchedAccount.displayName;
                //console.log(matchedAccount)
                accounts.push({
                    name: account,
                    display: displayName,
                    restarting: event.response[account],
                    maintenance: matchedAccount.maintenance
                });

                if(this.awaitingResponse && event.response[account]) {
                    this.$store.commit('addRestartedAccount', account);
                }
            }

            this.accounts = accounts;
            this.awaitingResponse = false;
        }
    }
}
</script>