<template>
    <div class="maintenance-widget">
        <b-form @submit.prevent="onMaintenanceChange">
            <div class="row">
                <div class="col-6 mb-2">
                    <b-form-checkbox v-model="maintenance">
                        <template v-if="maintenance">
                            Dashboard maintenance is enabled, email Sam and Katie before use.
                        </template>
                        <template v-else>
                            Dashboard maintenance is disabled, email Sam and Katie before use.
                        </template>
                    </b-form-checkbox>
                </div>
                <div class="col-6 text-right">
                    <b-button variant="primary" :disabled="isLoading" type="submit">
                        <template v-if="isLoading">
                            <b-spinner small></b-spinner>
                        </template>
                        Save
                    </b-button>
                </div>
            </div>
            <div class="row" v-if="maintenance">
                <div class="col-12">
                    <b-form-group label="Reason:" description="If a reason is set, it it shown to clients on the Maintenance page." v-if="maintenance">
                        <b-form-input v-model="reason"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
export default {
    name: 'maintenance',
    data() {
        return {
            maintenance: false,
            reason: "",
            isLoading: true
        };
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('maintenance', this.onMaintenance);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('maintenance', this.onMaintenance);
                this.hasSetupListeners = true;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'Maintenance',
                args: {}
            });
        },
        onMaintenance(event) {
            this.maintenance = event.response.maintenance;
            this.reason = event.response.reason;

            this.isLoading = false;
        },
        onMaintenanceChange() {
            this.isLoading = true;

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: this.$store.state.activeAccount,
                request: 'Maintenance',
                args: {
                    enabled: this.maintenance,
                    reason: this.reason
                }
            });
        }
    }
}
</script>